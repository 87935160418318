<script setup lang="ts">
import type { Resources } from './UiResourcesSlideover.vue'

defineProps<{
  resources: Resources
}>()

const emit = defineEmits<{
  close: [_: void]
  select: [index: number]
}>()
</script>

<template>
  <div
    data-ui="UiResourcesList"
    class="flex-1 side-panel border-l border-forgd-bgd-600 bg-forgd-bgd-100 font-display overflow-y-scroll flex flex-col"
  >
    <div
      class="h-[70px] flex-shrink-0 px-5 flex items-center justify-between border-b border-forgd-bgd-600 sticky top-0 bg-forgd-bgd-100 z-50"
    >
      <div class="text-sm/[1.5] font-semibold">
        Readings & Resources
      </div>

      <UButton
        size="sm"
        color="white"
        variant="ghost"
        icon="i-heroicons-x-mark"
        @click="emit('close')"
      />
    </div>

    <div class="flex-grow p-2">
      <button
        v-for="(resource, index) in resources"
        :key="resource.link"
        class="w-full flex items-center justify-between p-4"
        @click="emit('select', index)"
      >
        {{ resource.title }}

        <div class="flex gap-2 items-center justify-end">
          <div class="font-mono font-medium bg-forgd-gray-100 rounded-3xl px-2">
            {{ resource.type }}
          </div>

          <UIcon name="i-heroicons-arrow-right" class="w-4 h-4" />
        </div>
      </button>
    </div>
  </div>
</template>
