<script setup lang="ts">
import type { Resources } from './UiResourcesSlideover.vue'
import UiResourcesList from './UiResourcesList.vue'
import UiResourcesSlideover from './UiResourcesSlideover.vue'

export interface Props {
  resources: Resources
  showFooter?: boolean
  showNumResources?: boolean
  icon?: string
  buttonClass?: string
}

const props = withDefaults(defineProps<Props>(), {
  showFooter: true,
  showNumResources: true,
  icon: 'i-heroicons-arrow-right',
  buttonClass: '',
})

const selectedIndex = ref(-1)

const resource = computed(() => props.resources[selectedIndex.value])

const isOpen = ref(false)

function open() {
  isOpen.value = true
  if (props.resources.length === 1) {
    selectedIndex.value = 0
  }
}

function close() {
  isOpen.value = false
  selectedIndex.value = -1
}
</script>

<template>
  <UiButton :class="buttonClass" data-ui="UiResources/Button" variant="solid" @click="open">
    <slot name="label">
      Readings & Resources
    </slot>
    <div
      v-if="props.showNumResources"
      class="w-6 h-6 flex items-center justify-center rounded-full bg-forgd-primary-900 text-forgd-bgd-100 text-xs"
    >
      {{ resources.length }}
    </div>
    <UIcon v-else :name="icon" class="w-4 h-4" />
  </UiButton>
  <USlideover
    v-model="isOpen"
    data-ui="UiResources/Slideover"
    :ui="{ width: 'max-w-[560px]', overlay: { background: 'bg-transparent' } }"
  >
    <UiResourcesList
      v-if="resources.length > 1 && selectedIndex < 0"
      :resources
      @select="index => selectedIndex = index"
      @close="close"
    />
    <UiResourcesSlideover
      v-else-if="resource"
      :resource="resource"
      :show-footer="showFooter"
      @close="close"
    />
  </USlideover>
</template>
